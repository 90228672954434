:root {
    --bg-color: rgb(50, 50, 50);
    --card-color: rgb(23, 23, 23);
    --purple: rgb(123, 31, 162);
    --violet: rgb(103, 58, 183);
    --pink: rgb(244, 143, 177);
}

.home-container {
    flex-direction: column;
    display: flex;
    overflow: hidden;
    align-items: center;
    background-color: #000000;
    width: 100%;
    height: 100%;
}

.background {
    position: absolute;
    left: 0;
    top: var(--scroll);
    background-color: #000000;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(#ffffff40 1px, #080808 0);
    background-size: 40px 40px;
    background-attachment: inherit;
}

.background-blur {
    position: absolute;
    left: 0;
    top: 385px;
    background: linear-gradient(
                    to right,
                    rgba(0, 0, 0, 0) 0%,
                    #4e149650 50%,
                    rgba(0, 0, 0, 0) 100%
            );
    width: 100%;
    height: 50px;
}

.card-view {
    display: grid;
    row-gap: 30px;
    column-gap: 30px;
    column-count: 2;
    width: 80%;
    align-self: center;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    margin-bottom: 50px;
}

.video {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.header-container {
    display: flex;
    flex-direction: row;
    margin-top: 50px;
    margin-bottom: 30px;
    width: 70%;
}

.header {
    flex: 1;
    padding: 20px;
    display: flex;
}

.profile {
    max-height: 250px;
    max-width: 250px;
    aspect-ratio: 1;
    width: auto;
    border-radius: 1000px;
    padding: 8px;
    margin-right: 30px;
    background: linear-gradient(150deg, rgba(97,27,189, 0.5) 40%, rgb(97,27,189) 50%, rgba(97,27,189, 0.5) 60%);
    overflow: visible;
    animation: backgroundAnimation 20s linear infinite;
    background-size: 1000%;
    transition: transform 0.1s ease-in-out;
}

.profile:hover {
    transform: scale(1.05)
}

.flip {
    padding: 10px;
    display: flex;
    flex-direction: row;
}

@media only screen and (max-width: 1200px) {
    .header-container {
        width: 90%
    }
    .card-view {
        width: 80%
    }
}

@media only screen and (max-width: 900px) {
    .flip {
        flex-direction: column;
        align-items: center;
    }
    .badge-display {
        width: 100% !important;
        justify-content: center !important;
        align-items: center;
        align-content: center;
        margin-left: 10px;
    }
    .project-popup {
        height: 80vh !important;
        max-height: 800px !important;
        min-height: 400px !important;
    }
    .project-popup-bg {
        flex-direction: column-reverse !important;
        overflow-y: scroll;
    }
    .popup-header {
        border-radius: 0 0 0 0 !important;
    }
    .popup-video-card {
        min-height: 300px;
        height: 300px;
    }
}
@media only screen and (max-width: 600px) {

    .header{
        flex-direction: column;
        align-items: center;
    }
    .profile {
        margin-right: 0;
    }
}

@keyframes backgroundAnimation {
    0% {
        background-position: 0 0%;
    }
    100% {
        background-position: -1000% 0%;
    }
}

.profile-image {
    width: 100%;
    height: 100%;
    border-radius: 1000px;
    object-fit: cover;
    background-color: #111111;
    border-width: 2px;
    border-style: solid;
}

.title {
    font-family: RussoOne, sans-serif;
    font-size: 30px;
    color: white;
    text-align: left;
}

.subtitle {
    font-family: RussoOne, sans-serif;
    font-size: 18px;
    color: white;
    text-align: left;
}

.header-text {
    font-family: Roboto, sans-serif !important;
    font-size: 15px;
    color: white;
    text-align: left;
}

.header-body {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
}

.header-body span {
    font-family: RussoOne, sans-serif;
    color: white;
}

.badge-display {
    width: 170px;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-self: center;
    margin-right: 10px;
    justify-content: flex-end;
    align-items: center;
    align-content: flex-start;
}

.project-popup {
    position: relative;
    height: 90vh;
    min-height: 250px;
    max-height: 400px;
    display: flex;
    flex-direction: row;
    padding: 3px;
    border-radius: 15px;
    background: linear-gradient(to bottom right, #8f3bff, #4e1496 30%, #4e1496,#611bbd);
    box-shadow: #4e1496 0 0 15px;
}

.project-popup-bg {
    display: flex;
    border-radius: 15px;
    height: 100%;
    width: 100%;
    background-color: #111111;
}

.project-popup span {
    color: white;
    font-family: RussoOne, sans-serif;
    text-align: left;
}

.popup-header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
    padding: 20px;
    border-top-left-radius: 12px;
    border-bottom-right-radius: 15px;
    background-color: #4e149680;
}

.popup-video-card {
    height: calc(100% - 40px);
    aspect-ratio: 1;
    margin: 20px;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: #000000 0px 5px 10px;
}

.close-btn {
    position: absolute;
    margin: 10px;
    top: -60px;
    right: 0px;
    background-color: #222222;
    border: solid 2px #555555;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    z-index: 999;
    color: white;
    font-size: 30px;
    width: 40px;
    cursor: pointer;
    height: 40px;
    transition: all .05s ease-in-out;
    -moz-transition: all .05s ease-in-out;
}

.close-btn:hover {
    transform: scale(1.1);
}

.star {
    width: 20px;
    height: 20px;
    object-fit: contain;
    transform: scale(0);
    animation: 0.2s ease-out popupAnimation forwards;
}

.link-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.link {
    padding: 5px 15px 5px 15px;
    background-color: #4e1496;
    border-color: #611bbd;
    border-width: 2px;
    border-style: solid;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

.link:hover {
    transform: scale(1.05);
}

.scrollbox {
    flex: 1;
    overflow-y: scroll;
    display: flex;
    align-items: flex-start;
    padding: 10px;
    background-color: rgba(0,0,0,0.5);
    border: #ffffff30 solid 2px;
    border-radius: 15px;
}

.scrollbox span::selection {
    background-color: #611bbd;
    color: white;
}

.tag-row {
    gap: 5px;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.tag {
    padding: 2px;
    background-color: var(--colour);
    border-radius: 10px;
}

.tag-bg {
    padding: 3px 8px 3px 8px;
    background-color: rgba(0,0,0,0.4);
    border-radius: 8px;
}

.tag span {
    color: white;
    font-family: RussoOne, sans-serif;
    font-size: 14px;
}



@media only screen and (max-width: 750px) {
    .bento-grid {
        grid-template-columns: repeat(3, 1fr) !important;
        height: 1800px !important;
        grid-template-areas:
        "skilltree skilltree skilltree"
        "skilltree skilltree skilltree"
        "login login login"
        "login login login"
        "login login login"
        "login login login"
        "skilltree-app skilltree-app skilltree-app"
        "skilltree-app skilltree-app skilltree-app"
        "skilltree-app skilltree-app skilltree-app"
        "skilltree-app skilltree-app skilltree-app"
        "skilltree-app skilltree-app skilltree-app"
        "talos talos talos"
        "talos talos talos" !important;
    }
}
.bento-grid {
    width: 80%;
    height: 1000px;
    display: grid;
    gap: 1vw;
    margin-bottom: 100px;
    padding: 1vw;
    z-index: 3;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto;
    grid-template-areas:
    "skilltree skilltree skilltree login login login"
    "skilltree skilltree skilltree login login login"
    "skilltree-app skilltree-app skilltree-app login login login"
    "skilltree-app skilltree-app skilltree-app talos talos talos"
    "skilltree-app skilltree-app skilltree-app talos talos talos";
}

.bento-item {
    /*border: 2px solid #464545;
    border-radius: 20px;
    overflow: hidden;*/
}

.bento-grid .bento-item:nth-child(1) {
    grid-area: login;
}
.bento-grid .bento-item:nth-child(2) {
    grid-area: talos;
}
.bento-grid .bento-item:nth-child(3) {
    grid-area: skilltree;
}

.bento-grid .bento-item:nth-child(4) {
    grid-area: skilltree-app;
}

.moveBtn {
    width: 30px;
    height: 30px;
    min-width: 30px;
    background-color: white;
    color: black;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
}

.moveBtn:hover {
    transform: scale(1.2)
}

.moveBtn:focus{
    background-color: rgba(255,206,0,1);
}