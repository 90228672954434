
.card-view:hover .card::before {
    opacity: 1;
}
.card:hover::after {
    opacity: 1;
}

.card {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 250px;
    border-radius: 10px;
    flex-direction: column;
    position: relative;
    transition: transform .01s ease-in-out, opacity .1s ease-in-out;
    -moz-transition: transform .01s ease-in-out, opacity .1s ease-in-out;
    background: linear-gradient(to right, #222222, #666666, #222222);
    box-shadow: #00000080 0 10px 20px;
}

.card.inactive {
    opacity: 0.5;
}

.card div {
    z-index: 2;
}

.card-overlay {
    display: flex;
    overflow: hidden;
    justify-content: center;
    align-items: center;
}

.card-overlay-foreground {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    gap: 0;
    padding: 100px 10px 10px;
    background: rgb(0,0,0);
    background: -moz-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100px, rgba(0,0,0,0.8) 100%);
    background: -webkit-linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100px, rgba(0,0,0,0.8) 100%);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100px, rgba(0,0,0,0.8) 100%);
}

.card-overlay span {
    font-family: RussoOne, sans-serif;
    color: white;
}

.card-container {
    position: relative;
    background-color: transparent;
    transition: all .05s ease-in-out;
    -moz-transition: all .05s ease-in-out;
}

.scaleup:hover {
    transform: scale(1.1);
}


.card::before,
.card::after {
    border-radius: inherit;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: opacity 500ms;
    width: 100%;
}
.card::after {
    opacity: 0;
}

.card::before {
    background: radial-gradient(
            500px circle at var(--mouse-x) var(--mouse-y),
            rgba(165, 59, 255, 1),
            transparent 80%
    );
    z-index: 1;
}

.card::after {
    background: radial-gradient(
            600px circle at var(--mouse-x) var(--mouse-y),
            rgba(97, 13, 169, 0.2),
            transparent 60%
    );
    z-index: 3;
}

.card-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--card-color);
    border-radius: inherit;
    flex-direction: column;
    flex-grow: 1;
    inset: 3px;
    position: absolute;
    z-index: 1;
    overflow: hidden;
}
