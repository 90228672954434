
@keyframes background-pan {
    from {
        background-position: 0% center;
    }

    to {
        background-position: -200% center;
    }
}

@keyframes scale {
    from, to {
        transform: scale(0);
    }

    50% {
        transform: scale(1);
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(180deg);
    }
}

.magic {
    color: white;
    font-family: "Rubik", sans-serif;
    font-weight: 400;
    margin: 0px;
    font-size: 18px;
    text-align: center;
    display: inline-block;
    position: relative;
}

.magic > .magic-star {
    animation: scale 700ms ease forwards;
    display: block;
    height: 20px;
    left: var(--star-left);
    position: absolute;
    top: var(--star-top);
    width: 20px;
}

.magic > .magic-star > svg {
    animation: rotate 1000ms linear infinite;
    display: block;
    opacity: 0.7;
}

.magic > .magic-star > svg > path {
    fill: var(--violet);
}

.magic > .magic-text {
    animation: background-pan 3s linear infinite;
    background: linear-gradient(
            to right,
            var(--purple),
            var(--violet),
            var(--pink),
            var(--purple)
    );
    background-size: 200%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    white-space: nowrap;
}