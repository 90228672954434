.badge {
    width: 50px;
    height: 50px;
    margin: 0;
    background-color: #111111;
    border-style: solid;
    border-width: 2px;
    border-color: #555555;
    border-radius: 5px;
    padding: 5px;
    transition: all 0.1s ease-in-out;
}

.badge:hover {
    transform: scale(1.1)
}

.badge-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 5px;
}